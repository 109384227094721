import React from 'react';
import { Box, Center, Text, Heading, Button } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';

import WithAnimation from '@components/Common/WithAnimation';
import Image from '@components/Common/LazyImage';

import txtWording from './locales';

import { BG_IG_FILTER } from '@/constants/assets';
import useLang from '@hooks/useLang';

/**
 * function to render Filter
 * @returns {JSX.Element}
 */
function FilterSection() {
  const lang = useLang();
  const handleClickFilterIG = () => {
    window.open('https://www.instagram.com/ar/382785564209588/', '_blank');
  };

  return (
    <Box padding="42px 32px 62px 32px" bgColor="bgPrimary">
      <WithAnimation>
        <Heading
          size="2xl"
          textAlign="center"
          color="mainColorText"
          fontWeight="normal"
        >
          Instagram <br />
          Wedding Filter
        </Heading>
      </WithAnimation>
      <WithAnimation>
        <Text align="center" color="mainColorText" margin="16px 0">
          <span dangerouslySetInnerHTML={{ __html: txtWording.subTitle[lang] }} />
        </Text>
      </WithAnimation>
      <WithAnimation>
        <Box margin="24px 0 24px">
          <Center>
            <Image src={BG_IG_FILTER} maxW="80%" borderRadius="16px" boxShadow="md" />
          </Center>
        </Box>
      </WithAnimation>
      <WithAnimation>
        <Center marginBottom="24px">
          <Button
            leftIcon={<FaInstagram />}
            size="sm"
            fontWeight="light"
            bgColor="bgSecondary"
            color="alternativeDark"
            onClick={handleClickFilterIG}
          >
            {txtWording.textButton[lang]}
          </Button>
        </Center>
      </WithAnimation>
    </Box>
  );
}

export default React.memo(FilterSection);
